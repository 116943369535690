<template>
  <div id="page-ui-kit">
    <a-row :gutter="15">
      <a-col :span="1"></a-col>
      <a-col :span="11">
        <h1>Заголовок 1</h1>
        <h2>Заголовок 2</h2>
        <h3>Заголовок 3</h3>
        <h4>Заголовок 4</h4>
        <h5>Заголовок 5</h5>
        <h6>Заголовок 6</h6>

        <p>Есть много вариантов Lorem Ipsum, но <a href="">большинство из них</a> имеет не всегда приемлемые
          модификации, например,
          юмористические вставки или слова, <strong>которые даже отдалённо не напоминают</strong> латынь. Если вам нужен
          Lorem Ipsum для
          серьёзного проекта, вы наверняка не хотите какой-нибудь шутки, скрытой в середине абзаца. Также все другие
          известные генераторы Lorem Ipsum используют <b>один и тот же текст</b>, который они просто повторяют, пока не
          достигнут нужный объём.</p>

        <a-divider>Разделитель</a-divider>

        <p>Классический текст Lorem Ipsum, используемый с XVI века, <a href="">приведён ниже</a>. Также даны разделы
          1.10.32 и 1.10.33
          "de Finibus Bonorum et Malorum" Цицерона и их английский перевод, сделанный H. Rackham, 1914 год.</p>
        <ul>
          <li>Его популяризации в новое время послужили публикация листов Letraset</li>
          <li>Многие программы электронной вёрстки и редакторы HTML используют Lorem Ipsum</li>
          <li>Некоторые версии появились по ошибке, некоторые - намеренно.</li>
        </ul>
        <p>Весь комплекс обучения построен на прикладной модели и носит практический характер.</p>
        <ol>
          <li>Его популяризации в новое время послужили публикация листов Letraset</li>
          <li>Многие программы электронной вёрстки и редакторы HTML используют Lorem Ipsum</li>
          <li>Некоторые версии появились по ошибке, некоторые - намеренно.</li>
        </ol>

        <div>
          <a-badge count="25"/>
          <a-badge
              count="4"
              :number-style="{
        backgroundColor: '#fff',
        color: '#999',
        boxShadow: '0 0 0 1px #d9d9d9 inset',
      }"
          />
          <a-badge count="109" :number-style="{ backgroundColor: '#52c41a' }"/>
        </div>

      </a-col>
      <a-col :span="11">

        <a-spin/>

        <a-result
            :status="resultStatus"
            :title="resultStatus"
            sub-title="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
        >
          <template #extra>

            <HomeOutlined/>

            <a-button type="primary" @click="resultStatus = 'info'">
              Info
            </a-button>
            <a-button @click="resultStatus = 'success'">
              Success
            </a-button>
            <a-button type="dashed" @click="resultStatus = 'warning'">
              Warning
            </a-button>
            <a-button @click="resultStatus = 'error'" type="danger">
              Error
            </a-button>
            <br>
            <br>
            <a-button icon="search" @click="resultStatus = '404'">
              404
            </a-button>
            <a-button @click="resultStatus = '403'">
              403
            </a-button>
            <a-button shape="circle" @click="resultStatus = '500'">
              <template #icon>
                <BugOutlined/>
              </template>
            </a-button>
            <a-button :loading="true">
              Loading
            </a-button>
          </template>
        </a-result>

        <a-space>
          <a-select show-search>
            <a-select-option value="jack">
              jack
            </a-select-option>
            <a-select-option value="lucy">
              lucy
            </a-select-option>
          </a-select>

          <a-date-picker/>
          <a-time-picker/>

        </a-space>

        <a-divider/>

        <div style="width: 170px">
          <a-progress :percent="30" size="small"/>
          <a-progress :percent="50" size="small" status="active"/>
          <a-progress :percent="70" size="small" status="exception"/>
          <a-progress :percent="100" size="small"/>
        </div>

        <a-empty/>

        <a-divider/>

        <a-space>
          <a-button type="primary" @click="visible = true">
            Show Modal
          </a-button>
          <a-button @click="info">
            Show info
          </a-button>
          <a-button @click="confirm">
            Show confirm
          </a-button>
          <a-popconfirm title="Question?">
            <a href="#">Click to confirm</a>
          </a-popconfirm>
        </a-space>

        <a-modal v-model:visible="visible" title="Locale Modal">
          <p>Locale Modal</p>
        </a-modal>

      </a-col>
      <a-col :span="1"></a-col>
    </a-row>
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue';
import { HomeOutlined, BugOutlined, } from '@ant-design/icons-vue';
import { ref } from "@vue/reactivity";

export default {
  name: 'PageUiKit228',
  setup() {
    const visible = ref(false);
    const resultStatus = ref('info');

    const doNothing = async (e) => {
      e.preventDefault();
    };
    return {
      visible, resultStatus, doNothing,
    };
  },
  methods: {
    info() {
      Modal.info({
        title: 'some info',
        content: 'some info',
      });
    },
    confirm() {
      Modal.confirm({
        title: 'some info',
        content: 'some info',
      });
    },
  },
  components: { HomeOutlined, BugOutlined },
};
</script>
